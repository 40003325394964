import { Body, Button, Divisor, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'
import { BorderRoundWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { CosmicjsCorpoClinicoMetadataEspecialidade } from 'site/src/data/graphql/graphql-types'
import {
  SpecialistCellTitle,
  SpecialistListCellContentWrapper,
  SpecialistListCellImgWrapper,
  SpecialistListCellStyled,
  imgSize,
  Specialtie,
  ButtonWrapper,
  SpecialtieContainer,
  SpecialtieTitle,
  CompleteDescriptionContainer
} from './specialist-list-cell-new.component.style'

import { Grid } from '@material-ui/core'

export interface SpecialistListCell {
  imgSrc: string
  imgAlt: string
  title: string
  specialities?: CosmicjsCorpoClinicoMetadataEspecialidade[]
  linkText?: string
  linkTo: string
  id: string
  descricaoCompleta: string
  descricaoSimples: string
}
export const SpecialistListCellNew: React.FunctionComponent<SpecialistListCell> = ({
  id,
  ...props
}) => {
  const [opened, setOpened] = React.useState(false)
  return (
    <ButtonWrapper id={`anchor-${id}`} onClick={() => setOpened(!opened)}>
      <SpecialistListCellStyled id={id}>
        <Grid container>
          <Grid item xs={4} sm={2} md={1}>
            <SpecialistListCellImgWrapper>
              <BorderRoundWrapper>
                <LazyLoadImage
                  src={props.imgSrc}
                  aspectRatio={1}
                  width={imgSize}
                  height={imgSize}
                  alt={props.imgAlt}
                />
              </BorderRoundWrapper>
            </SpecialistListCellImgWrapper>
          </Grid>
          <Grid item xs={8} sm={8} md={9}>
            <SpecialistListCellContentWrapper>
              <SpecialistCellTitle>{props.title}</SpecialistCellTitle>
              <SpecialtieContainer>
                <SpecialtieTitle>Especialidades: </SpecialtieTitle>
                {props.specialities?.map(specialtie => (
                  <Specialtie>{specialtie.title}</Specialtie>
                ))}
              </SpecialtieContainer>
            </SpecialistListCellContentWrapper>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Button id="button-send-email-contact" kind="secondary" expanded>
              {!opened ? 'Ver mais' : 'Ver menos'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            {opened && (
              <CompleteDescriptionContainer
                dangerouslySetInnerHTML={{ __html: props.descricaoCompleta }}
              />
            )}
          </Grid>
        </Grid>

        <Separator />
      </SpecialistListCellStyled>
    </ButtonWrapper>
  )
}
