import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'

import { Wrapper } from './styles'

const RadioFilter: React.FC = ({ options, filterName, direction, onChange }: any) => {
  const [value, setValue] = React.useState(options.length > 0 ? options[0].value : '')

  const handleChange = event => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  return (
    <Wrapper>
      <RadioGroup
        row
        aria-label="gender"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormLabel component="legend">{filterName}</FormLabel>
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            control={<Radio size="small" />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </Wrapper>
  )
}

export default RadioFilter
