import { Separator } from 'atomic/legacy/obj.box'
import { Form } from 'atomic/legacy/obj.form'
import { ListNoBullets } from 'site/src/components/atm.list-no-bullets/ListNoBullets.component'
import { FieldsWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { PaginationContainer } from 'site/src/components/legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from 'site/src/components/legacy/pagination-wrapper.component'
import { SpecialitiesField } from 'site/src/components/legacy/specialities-field.component'
import NumberOfResults from 'site/src/components/mol.number-of-results/number-of-results.component'
import { FuseSearch } from 'site/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from 'site/src/components/mol.local-search/fuse-search.utils'
import { SpecialistListCell } from './component/specialist-list-cell.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import {
  CosmicjsCorpoClinicoMetadataEspecialidade,
  CosmicjsEspecialidadesConnection,
  CosmicjsInformacoesEstaticasMetadataSeo
} from 'site/src/data/graphql/graphql-types'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import { getClientAndDoctorUrl, normalizeUrl } from 'utils/url'
import { Col, Grid, Row } from 'atomic'
import { graphql, navigate } from 'gatsby'
import * as queryString from 'query-string'
import React from 'react'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'
import OurSpecialistsTemplateOld from './our-specialists-list-old'
import OurSpecialistsTemplateNew from './our-specialists-list-new'

interface Item {
  title: string
  url: string
  imgSrc: string
  specialities: CosmicjsCorpoClinicoMetadataEspecialidade[]
}

export interface OurSpecialistsTemplateProps {
  allCosmicjsEspecialidades: Partial<CosmicjsEspecialidadesConnection>
  list: Item[]
}

export interface OurSpecialistsTemplateQueryParams {
  especialidade: string
  [key: string]: unknown
}

const OurSpecialistsTemplate: React.FunctionComponent<PageProps<
  OurSpecialistsTemplateProps
>> = props => {
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? (
          <OurSpecialistsTemplateNew {...props} />
        ) : (
          <OurSpecialistsTemplateOld {...props} />
        )}
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default OurSpecialistsTemplate

export const query = graphql`
  query OurSpecialistsTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "corpo-clinico-lista" }) {
      metadata {
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
        marcas {
          marca
        }
      }
    }
  }
`
