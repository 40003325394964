import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 12px;
  padding-left: 12px;
  align-items: center;

  .MuiFormLabel-root {
    display: flex;
    align-items: center;
    padding-right: 7px;

    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #373a3a;
  }
  .MuiFormControlLabel-label {
    font-family: Signika !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #373a3a;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0px;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: ${props => props.theme.color.primary};

    :hover {
      color: ${props => props.theme.color.primary};
    }
  }
`
