import { Border, Color, H3, Spacing } from 'atomic'
import styled from 'styled-components'

export const SpecialistCellTitle = styled(H3)``
export const imgSize = 76

export const SpecialistListCellStyled = styled.li`
  overflow: auto;
  background-color: ${Color.White};
  :hover {
    ${SpecialistCellTitle} {
      text-decoration: underline;
    }
  }
`
export const SpecialistListCellContentWrapper = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: ${imgSize}px;
`

export const SpecialistListCellLinkWrapper = styled.div`
  flex: 1;
`

export const SpecialistListCellImgWrapper = styled.div`
  float: left;
  margin-right: ${Spacing.Medium};
  margin-left: 0;
  border-radius: ${Border.Radius};
  overflow: hidden;
  background-color: ${Color.GrayXLight};
`
