import { Body, Button, Divisor, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'
import { BorderRoundWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import {
  SpecialistCellTitle,
  SpecialistListCellContentWrapper,
  SpecialistListCellImgWrapper,
  SpecialistListCellLinkWrapper,
  SpecialistListCellStyled,
  imgSize
} from './specialist-list-cell.component.style'

export interface SpecialistListCell {
  imgSrc: string
  imgAlt: string
  title: string
  bodyHTML?: string
  linkText?: string
  linkTo: string
  id: string
}
export const SpecialistListCell: React.FunctionComponent<SpecialistListCell> = ({
  id,
  ...props
}) => (
  <SpecialistListCellStyled id={id}>
    <a id={`anchor-${id}`} href={props.linkTo}>
      <Separator />
      <SpecialistListCellImgWrapper>
        <BorderRoundWrapper>
          <LazyLoadImage
            src={props.imgSrc}
            aspectRatio={1}
            width={imgSize}
            height={imgSize}
            alt={props.imgAlt}
          />
        </BorderRoundWrapper>
      </SpecialistListCellImgWrapper>

      <SpecialistListCellContentWrapper>
        <SpecialistCellTitle cell>{props.title}</SpecialistCellTitle>
        <Separator />
        {props.bodyHTML && (
          <>
            <Body>
              <div dangerouslySetInnerHTML={{ __html: props.bodyHTML }} />
            </Body>
          </>
        )}
        {props.linkText && (
          <>
            <Separator />
            <SpecialistListCellLinkWrapper>
              <Button id='button-specialist-list' kind='link' to={props.linkTo}>
                {props.linkText}
              </Button>
            </SpecialistListCellLinkWrapper>
          </>
        )}
      </SpecialistListCellContentWrapper>
      <Separator />
      <Divisor />
    </a>
  </SpecialistListCellStyled>
)
