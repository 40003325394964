import { Separator } from 'atomic/legacy/obj.box'
import { Form } from 'atomic/legacy/obj.form'
import { ListNoBullets } from 'site/src/components/atm.list-no-bullets/ListNoBullets.component'
import { FieldsWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { PaginationContainer } from 'site/src/components/legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from 'site/src/components/legacy/pagination-wrapper.component'
import { SpecialitiesField } from 'site/src/components/legacy/specialities-field.component'
import NumberOfResults from 'site/src/components/mol.number-of-results/number-of-results.component'
import { FuseSearch } from 'site/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from 'site/src/components/mol.local-search/fuse-search.utils'
import { SpecialistListCell } from './component/specialist-list-cell.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'

import {
  CosmicjsCorpoClinicoMetadataEspecialidade,
  CosmicjsEspecialidadesConnection,
  CosmicjsInformacoesEstaticasMetadataSeo
} from 'site/src/data/graphql/graphql-types'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import { getClientAndDoctorUrl, normalizeUrl } from 'utils/url'
import { Col, Grid, Row } from 'atomic'
import { navigate } from 'gatsby'
import * as queryString from 'query-string'
import React from 'react'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'

interface Item {
  title: string
  url: string
  imgSrc: string
  specialities: CosmicjsCorpoClinicoMetadataEspecialidade[]
}

export interface OurSpecialistsTemplateProps {
  allCosmicjsEspecialidades: Partial<CosmicjsEspecialidadesConnection>
  list: Item[]
}

export interface OurSpecialistsTemplateQueryParams {
  especialidade: string
  [key: string]: unknown
}

const OurSpecialistsTemplateOld: React.FunctionComponent<PageProps<
  OurSpecialistsTemplateProps
>> = props => {
  const queryParam: OurSpecialistsTemplateQueryParams = queryString.parse(
    props.location.search
  ) as any
  const especialidade = queryParam.especialidade
  const seoData = props.data.cosmicjsInformacoesEstaticas.metadata
    .seo as CosmicjsInformacoesEstaticasMetadataSeo

  const onValueChange = value => {
    const param: OurSpecialistsTemplateQueryParams = value.value && {
      especialidade: value.value
    }
    navigate(`${props.location.pathname}?${queryString.stringify(param)}`)
  }
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const [items, setItems] = React.useState(props.pageContext.list)
  const data = items.filter(
    item =>
      !especialidade ||
      (item.specialities &&
        item.specialities.map(speciality => speciality.slug).includes(especialidade))
  )
  const onResult = list => {
    if (list) {
      setItems(list)
    }
  }
  const indexParams: IndexParams<Item> = {
    data: props.pageContext.list,
    keys: [
      {
        name: 'title',
        weight: 0.8
      },
      {
        name: 'specialities.title' as any,
        weight: 0.2
      }
    ]
  }

  return (
    <Grid>
      <TitleWithBreadcrumbRow title={'Corpo clínico'} />
      <Row mb bottom="xs">
        <Col xs={12} md={8}>
          <FieldsWrapper>
            <FuseSearch
              id="input-our-specialists-search"
              indexParams={indexParams}
              onResultChange={onResult}
              placeholder="Buscar por nome"
            />
          </FieldsWrapper>
          <Separator />
        </Col>
        <Col xs={12} mdOffset={1} md={3}>
          <Form id="form-our-specialists" onSubmit={null}>
            <SpecialitiesField
              allCosmicjsEspecialidades={props.pageContext.allCosmicjsEspecialidades}
              onValueChange={onValueChange}
              initialValueSlug={especialidade}
            />
          </Form>
          <Separator />
        </Col>
      </Row>
      {data.length > 0 ? (
        <PaginationContainer>
          {paginationProps => {
            const startIndex =
              (paginationProps.pagination.current - 1) * paginationProps.limit.current
            const endIndex = paginationProps.pagination.current * paginationProps.limit.current

            const list = data.slice(startIndex, endIndex)
            const numberOfItems = data.length

            const specialistsCellsContent = list.map(specialist => ({
              title: specialist.title,
              imgSrc: specialist.imgSrc,
              imgAlt: `Foto do médico ${specialist.title}`,
              linkTo: specialist.url,
              bodyHtml: specialist.specialities && getSpecialitiesBody(specialist.specialities)
            }))
            const siteUrl = props.data.site.siteMetadata.siteUrl

            return (
              <>
                <SEO
                  jsonld={getListJsonLd(
                    list.map(item => ({
                      url: normalizeUrl(`${siteUrl}${item.url}`)
                    }))
                  )}
                  socialMedia={{
                    canonicalUrl: getCanonicalUrl(siteUrl, appPaths.ourSpecialists.path),
                    title: seoData.titulo,
                    image: seoData.imagem.url,
                    imageAlt: seoData.imagemAlternativa,
                    description: seoData.descricao
                  }}
                />
                <Row mt mb>
                  <Col>
                    <NumberOfResults numberOfItems={numberOfItems} />
                    <Separator />
                  </Col>
                </Row>
                <Row mb>
                  <Col xs={12} md={8}>
                    <ListNoBullets id="list-our-specialists">
                      {specialistsCellsContent.map((item, index) => (
                        <SpecialistListCell
                          key={item.title}
                          title={item.title}
                          imgSrc={item.imgSrc}
                          bodyHTML={item.bodyHtml}
                          imgAlt={item.imgAlt}
                          linkTo={item.linkTo}
                          id={`specialist-item-${index}`}
                        />
                      ))}
                    </ListNoBullets>
                  </Col>
                </Row>
                <PaginationRow
                  pagination={{
                    ...paginationProps.pagination,
                    total: data.length
                  }}
                  limit={paginationProps.limit}
                />
              </>
            )
          }}
        </PaginationContainer>
      ) : (
        <Placeholder
          icon={<SvgBuscaSemResultado />}
          title={'Nenhum resultado encontrado'}
          description="Tente buscar outro termo ou ligue para nossa central de atendimento."
        />
      )}
    </Grid>
  )
}

export default OurSpecialistsTemplateOld

function getSpecialitiesBody(specialities: CosmicjsCorpoClinicoMetadataEspecialidade[]): string {
  const titles = specialities.map(item => item.title)
  return titles && titles.length > 1
    ? `<b>Especialidades: </b> ${titles.join(', ')}`
    : `<b>Especialidade: </b> ${titles[0]}`
}
