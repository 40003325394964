import { EnhancedSelectNew } from '@root/../atomic/legacy/atm.enhanced-select-new/enhanced-select-new.component'
import { FloatingLabelNew } from '@root/../atomic/legacy/atm.floating-label-new/floating-label-new.component'
import {
  EnhancedSelect,
  SelectOption
} from 'atomic/legacy/atm.enhanced-select/enhanced-select.component'
import { Form } from 'atomic/legacy/obj.form'
import * as React from 'react'
import { CosmicjsEspecialidadesConnection } from '../../data/graphql/graphql-types'

export interface SpecialitiesProps {
  allCosmicjsEspecialidades: Partial<CosmicjsEspecialidadesConnection>
  initialValueSlug: string
  onValueChange: (value: SelectOption) => void
}

export const allSpecilities = { label: 'Todas', value: '' }

export const SpecialitiesField = (props: SpecialitiesProps) => {
  const specialities = props.allCosmicjsEspecialidades.edges
    .map(item => ({ label: item.node.title, value: item.node.slug } as SelectOption))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
  specialities.unshift(allSpecilities)

  const initialValue = props.initialValueSlug
    ? specialities.find(item => item.value === props.initialValueSlug)
    : allSpecilities

  return process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? (
    <Form.Field name="select" onValueChange={props.onValueChange}>
      <FloatingLabelNew label={'Filtrar por especialidade'}>
        <EnhancedSelectNew id="select-specialist-field" options={specialities} />
      </FloatingLabelNew>
    </Form.Field>
  ) : (
    <Form.Field
      name="select"
      label="Filtrar por especialidade:"
      onValueChange={props.onValueChange}
      initialValue={initialValue}
    >
      <EnhancedSelect id="select-specialist-field" options={specialities} />
    </Form.Field>
  )
}
