import { Border, Color, H3, Spacing } from 'atomic'
import styled from 'styled-components'

export const SpecialistCellTitle = styled.span`
  font-family: Signika;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #373f41;
  width: 100%;
  text-align: left;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`
export const imgSize = 76

export const ButtonWrapper = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
`

export const SpecialistListCellStyled = styled.li`
  overflow: auto;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 16px 24px;
  border-radius: 8px;

  :hover {
    /* ${SpecialistCellTitle} {
      text-decoration: underline;
    } */
  }

  @media (max-width: 425px) {
  padding: 10px 14px;
  }

`
export const SpecialistListCellContentWrapper = styled.div`
  padding: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    padding: 5px;
  }
`

export const CompleteDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    strong {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #373f41;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 8px 0px;
    }

    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    text-align: left;
  }
`

export const SpecialtieContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const SpecialtieTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;

  @media (max-width: 425px) {
    font-size: 10px;
  }
`

export const Specialtie = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 12px;
  background-color: ${props => props.theme.color.primaryLight};
  border-radius: 31px;
  font-size: 12px;
  line-height: 18px;
  margin: 3px 5px;

  @media (max-width: 425px) {
    font-size: 10px;
  }
`

export const SpecialistListCellImgWrapper = styled.div`
  float: left;
  margin-right: ${Spacing.Medium};
  margin-left: 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${Color.GrayXLight};
`
