import { Separator } from 'atomic/legacy/obj.box'
import styled from 'styled-components'

import { Form } from 'atomic/legacy/obj.form'
import { ListNoBullets } from 'site/src/components/atm.list-no-bullets/ListNoBullets.component'
import { FieldsWrapperNew } from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { PaginationContainer } from 'site/src/components/legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from 'site/src/components/legacy/pagination-wrapper.component'
import { SpecialitiesField } from 'site/src/components/legacy/specialities-field.component'
import NumberOfResults from 'site/src/components/mol.number-of-results/number-of-results.component'
import { FuseSearch } from 'site/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from 'site/src/components/mol.local-search/fuse-search.utils'
import { SpecialistListCell } from './component/specialist-list-cell.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import {
  CosmicjsCorpoClinicoMetadataEspecialidade,
  CosmicjsEspecialidadesConnection,
  CosmicjsInformacoesEstaticasMetadataSeo
} from 'site/src/data/graphql/graphql-types'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import { getClientAndDoctorUrl, normalizeUrl } from 'utils/url'
import { Container, Grid } from '@material-ui/core'

import { navigate } from 'gatsby'
import * as queryString from 'query-string'
import React from 'react'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'
import { FloatingLabelNew } from 'atomic/legacy/atm.floating-label-new/floating-label-new.component'
import { SpecialistListCellNew } from './component/specialist-list-cell-new.component'
import RadioFilter from './component/RadioFilter'

export const SelectContainer = styled.div`
  .select__value-container {
    padding: 9px;
  }
`

interface Item {
  title: string
  url: string
  imgSrc: string
  specialities: CosmicjsCorpoClinicoMetadataEspecialidade[]
}

export interface OurSpecialistsTemplateProps {
  allCosmicjsEspecialidades: Partial<CosmicjsEspecialidadesConnection>
  list: Item[]
}

export interface OurSpecialistsTemplateQueryParams {
  especialidade: string
  [key: string]: unknown
}

const OurSpecialistsTemplateNew: React.FunctionComponent<PageProps<
  OurSpecialistsTemplateProps
>> = props => {
  const queryParam: OurSpecialistsTemplateQueryParams = queryString.parse(
    props.location.search
  ) as any
  const especialidade = queryParam.especialidade
  const seoData = props.data?.cosmicjsInformacoesEstaticas.metadata
    .seo as CosmicjsInformacoesEstaticasMetadataSeo

  const onValueChange = value => {
    const param: OurSpecialistsTemplateQueryParams = value.value && {
      especialidade: value.value
    }
    navigate(`${props.location.pathname}?${queryString.stringify(param)}`)
  }

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const [items, setItems] = React.useState(props.pageContext.list)

  const onResult = list => {
    if (list) {
      setItems(list)
    }
  }
  const indexParams: IndexParams<Item> = {
    data: props.pageContext.list,
    keys: [
      {
        name: 'title',
        weight: 0.8
      },
      {
        name: 'specialities.title' as any,
        weight: 0.2
      }
    ]
  }

  const marcas = props.data?.cosmicjsInformacoesEstaticas.metadata.marcas.map(marca => ({
    value: marca.marca,
    name: marca.marca.charAt(0).toUpperCase() + marca.marca.slice(1)
  }))

  const [marca, setMarca] = React.useState(marcas.length > 0 ? marcas[0].value : '')

  const data = items
    .filter(
      item =>
        !especialidade ||
        (item.specialities &&
          item.specialities.map(speciality => speciality.slug).includes(especialidade))
    )
    .filter(item => item.marca.includes(marca))

  const onChangeMarca = marca => setMarca(marca)

  return (
    <Container maxWidth="md">
      <TitleWithBreadcrumbRow title="Corpo clínico" />
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <FieldsWrapperNew>
            <FloatingLabelNew label={'Buscar por nome'}>
              <FuseSearch
                id="input-our-specialists-search"
                indexParams={indexParams}
                onResultChange={onResult}
              />
            </FloatingLabelNew>
          </FieldsWrapperNew>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectContainer>
            <Form id="form-our-specialists" onSubmit={null}>
              <SpecialitiesField
                allCosmicjsEspecialidades={props.pageContext.allCosmicjsEspecialidades}
                onValueChange={onValueChange}
                initialValueSlug={especialidade}
              />
            </Form>
          </SelectContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <RadioFilter options={marcas} filterName="Filtrar por:" onChange={onChangeMarca} />
        </Grid>

        {data.length > 0 ? (
          <PaginationContainer>
            {paginationProps => {
              const startIndex =
                (paginationProps.pagination.current - 1) * paginationProps.limit.current
              const endIndex = paginationProps.pagination.current * paginationProps.limit.current

              const list = data.slice(startIndex, endIndex)
              const numberOfItems = data.length

              const specialistsCellsContent = list.map(specialist => ({
                title: specialist.title,
                imgSrc: specialist.imgSrc,
                imgAlt: `Foto do médico ${specialist.title}`,
                linkTo: specialist.url,
                specialities: specialist.specialities,
                descricaoSimples: specialist.descricaoSimples,
                descricaoCompleta: specialist.descricaoCompleta,
                marca: specialist.marca
              }))

              const siteUrl = props.data?.site.siteMetadata.siteUrl

              return (
                <>
                  <SEO
                    jsonld={getListJsonLd(
                      list.map(item => ({
                        url: normalizeUrl(`${siteUrl}${item.url}`)
                      }))
                    )}
                    socialMedia={{
                      canonicalUrl: getCanonicalUrl(siteUrl, appPaths.ourSpecialists.path),
                      title: seoData?.titulo,
                      image: seoData?.imagem.url,
                      imageAlt: seoData?.imagemAlternativa,
                      description: seoData?.descricao
                    }}
                  />
                  <Grid container spacing={4}>
                    {/* <Grid item xs={12}>
                        <NumberOfResults numberOfItems={numberOfItems} />
                      </Grid> */}
                    <Grid item xs={12}>
                      <ListNoBullets id="list-our-specialists">
                        <Grid container spacing={2}>
                          {specialistsCellsContent.map((item, index) => (
                            <Grid item xs={12}>
                              <SpecialistListCellNew
                                key={item.title}
                                title={item.title}
                                imgSrc={item.imgSrc}
                                specialities={item.specialities}
                                descricaoSimples={item.descricaoSimples}
                                descricaoCompleta={item.descricaoCompleta}
                                // marca={item.marca}
                                imgAlt={item.imgAlt}
                                linkTo={item.linkTo}
                                id={`specialist-item-${index}`}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </ListNoBullets>
                    </Grid>

                    <Grid item xs={12}>
                      <PaginationRow
                        pagination={{
                          ...paginationProps.pagination,
                          total: data.length
                        }}
                        limit={paginationProps.limit}
                      />
                    </Grid>
                  </Grid>
                </>
              )
            }}
          </PaginationContainer>
        ) : (
          <Placeholder
            icon={<SvgBuscaSemResultado />}
            title={'Nenhum resultado encontrado'}
            description="Tente buscar outro termo ou ligue para nossa central de atendimento."
          />
        )}
      </Grid>
    </Container>
  )
}

export default OurSpecialistsTemplateNew
